import React from 'react'

import Layout from '../../components/Layout'
import PressRoll from '../../components/PressRoll'
import TitleBar from '../../components/TitleBar'
import Button from '../../components/Button'
import FeaturedIn from '../../components/FeaturedIn'
import { themeStyles, presets } from '../../utils/theme'
import { MdFileDownload } from 'react-icons/md';


const styles = {
  contentContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    margin: `${themeStyles.elementMargin.md} 0`,
    ...themeStyles.contentWidth,
  },
  pressDetails: {
    display: 'none',
    [presets.Tablet]: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'center',
    }
  },
  pressDetailsUnderBanner: {
    padding: '20px',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    [presets.Tablet]: {
      display: 'none',
    }
  },
  mailTo: {
    [presets.Tablet]: {
      color: 'white',
      fontWeight: 500,
      textDecoration: 'none'
    }
  },
  mediaInquiries: {
    marginBottom: '20px',
    [presets.Tablet]: {
      marginBottom: '0px',
      color: 'white',
      fontWeight: 300,
      marginRight: '20px'
    }
  }
}
const renderIndex = () => {
  const buttonText = (
    <span css={{display: 'flex', flexDirection: 'row', alignItems: 'center', padding: '0px 5px'}}>
      <MdFileDownload
        style={{ marginRight: '10px' }}
        size={20}
      />
      Download Media Kit
    </span>
  )
  const pressDetails = (
    <React.Fragment>
      <span css={styles.mediaInquiries}>
        Media inquiries:
        {` `}
        <a
          css={styles.mailTo}
          href='mailto:press@goloansnap.com'
        >
          press@goloansnap.com
        </a>
      </span>
      <Button
        href="/LoanSnap_media_kit.zip"
        secondary
        target="_blank"
        text={buttonText}
        style={{ width: '200px'}}
        analyticsTargetName='Download Media Kit'
      />
    </React.Fragment>
  )
  return (
    <Layout metadataTitle='LoanSnap - In the News' metadataDescription='View the latest news from LoanSnap'>
      <TitleBar
          title="Press"
      >
        <div css={styles.pressDetails}>
          {pressDetails}
        </div>
      </TitleBar>
      <div css={styles.pressDetailsUnderBanner}>
        {pressDetails}
      </div>
      <div css={styles.contentContainer}>
        <h1 css={[themeStyles.sectionHeader, { textAlign: 'center', width: '100%', padding: '0 20px', fontWeight: 600 }]}>
          In the News
        </h1>
        <FeaturedIn />
        <PressRoll pressType='news'/>
        <h1 css={[themeStyles.sectionHeader, { textAlign: 'center', width: '100%', padding: '0 20px', fontWeight: 600, paddingTop: '40px' }]}>
          Press Releases
        </h1>
        <PressRoll pressType='press-release' />
      </div>
    </Layout>
  )
}

export default renderIndex
