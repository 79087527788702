import React from 'react'
import { themeStyles, colors, presets } from '../utils/theme'

const styles = {
  outerTitleBar: {
    backgroundColor: colors.blueGreen,
  },
  titleBar: {
    color: colors.white,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    ...themeStyles.contentWidth,
    ...themeStyles.textPadding,
    height: '95px',
  },
  titleLink: {
    textDecoration: 'none',
    color: colors.white,
    marginLeft: '30px',
    display: 'none',
    [presets.Tablet]: {
      display: 'block',
    }
  },
  title: {
    color: colors.white,
    margin: 0,
  }
}

const TitleBar = ({ title, links=[], linkAlign='space-between', children }) => (
  <div css={styles.outerTitleBar}>
    <div css={[styles.titleBar, {justifyContent: linkAlign}]}>
      <h1 css={styles.title}>
        {title}
      </h1>
      {links.map(link => (
        <a css={styles.titleLink} href={link.link} key={link.link}>
          {link.text}
        </a>
      ))}
      {children}
    </div>
  </div>
)

export default TitleBar
