import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { graphql, StaticQuery } from 'gatsby'
import { themeStyles, colors, presets } from '../utils/theme'
import Button from '../components/Button'

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'flex-start',
    padding: '10px',
    flex: 1,
    alignSelf: 'stretch',
    textDecoration: 'none',
    [presets.Tablet]: {
      flexDirection: 'row',
    }
  },
  name: {
    color: colors.blueGreen,
  },
  title: {
    marginTop: '10px',
    color: colors.mediumGrey,
  },
  columnContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'center',
    ...themeStyles.contentWidth,
  },
  description: {
    lineHeight: '1.2em',
    color: colors.grey,
  },
  spacer: {
    display: 'flex',
    flex: 1,
  },
  text: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    alignItems: 'center',
    alignSelf: 'stretch',
    textAlign: 'center',
    color: colors.darkGreen,
  },
  mediaOutlet: {
    backgroundColor: colors.darkGreen,
    color: 'white',
    padding: '5px 30px',
    marginBottom: '10px',
  },
  readMore: {
    color: colors.shamrock,
    fontSize: '0.8em',
  },
  buttonHolder: {
    alignSelf: 'stretch',
    display: 'flex',
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
  }
}

const PressRoll = ({pressType, data}) => {
  const { edges: posts } = data.allMarkdownRemark
  const relevantPosts = posts.filter(n => n.node.frontmatter.pressType === pressType)
  const [maxArticleCount, setMaxArticleCount] = useState(4)
  let currentDisplayedPosts = [...relevantPosts]
  currentDisplayedPosts.length = Math.min(relevantPosts.length, maxArticleCount)
  return (
    <div css={styles.columnContainer}>
      {currentDisplayedPosts &&
        currentDisplayedPosts.map(({ node: post }) => (
          <a
            href={post.frontmatter.href}
            key={post.id}
            target='_blank'
            rel="noopener noreferrer"
            css={styles.container}
          >
            <div css={styles.text}>
              <div css={[styles.mediaOutlet, { backgroundColor: pressType === 'press-release' ? colors.blueGreen : null }]}>
                {post.frontmatter.mediaOutlet}
              </div>
              <h2 css={{ marginTop: '10px', fontWeight: 400, alignSelf: 'stretch'}}>
                {post.frontmatter.title}
              </h2>
              <p css={styles.readMore}>
                READ MORE >
              </p>
            </div>
          </a>
      ))}
      <div css={styles.buttonHolder}>
        {relevantPosts.length > maxArticleCount &&
          <Button
            to={"#"}
            secondary
            text={pressType === 'news' ? 'More Articles' : 'More Press Releases'}
            style={{fontSize: '20px', width: '180px' }}
            onClick={(e) => {
              e.preventDefault()
              setMaxArticleCount(maxArticleCount + 4)
            }}
            analyticsTargetName={pressType === 'news' ? 'More Articles' : 'More Press Releases'}
          />
        }
      </div>
    </div>
  )
}

PressRoll.propTypes = {
  data: PropTypes.shape({
    allMarkdownRemark: PropTypes.shape({
      edges: PropTypes.array,
    }),
  }),
}

export default ({ pressType }) => (
  <StaticQuery
    query={graphql`
      query PressRollQuery {
        allMarkdownRemark(
          sort: { order: DESC, fields: [frontmatter___date] }
          filter: { frontmatter: { templateKey: { eq: "press-link" } } }
        ) {
          edges {
            node {
              excerpt(pruneLength: 400)
              id
              fields {
                slug
              }
              frontmatter {
                pressType
                mediaOutlet
                title
                description
                templateKey
                date(formatString: "MMMM DD, YYYY")
                href
              }
            }
          }
        }
      }
    `}
    render={(data, count) => <PressRoll data={data} count={count} pressType={pressType} />}
  />
)
